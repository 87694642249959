import styled from "styled-components";
import Icon from "../../icons";
import { media } from "../../styles/mediaQueries";

export const Container = styled.div`
  align-items: center;
  border: 1px solid;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const StyledIcon = styled(Icon)`
  fill: ${(props) => props.theme.brandColor};
  height: 10rem;
  margin: 0 0 4rem 0;
  width: 30rem;
`;

export const Text = styled.p`
  color: ${(props) => props.theme.primaryFontColour};
  font-size: 1.3rem;
  margin: 0;
  text-align: center;

  span {
    cursor: pointer;
    text-decoration: underline;
  }

  ${(props) =>
    props.big &&
    `
    color: ${props.theme.brandColour};
    font-size: 7rem;
    font-family: ${props.theme.headingFontFamily};
    font-weight: 800;
    letter-spacing: .1rem;
    margin: 0;
  `};

  ${media.tablet`
    font-size: 1.4rem;
    ${(props) =>
      props.big &&
      `
      font-size: 7rem;
      color: ${props.theme.primaryFontColour};
    `}
  `}
`;
