import React from "react";
import Router from "next/router";
import { Container, StyledIcon, Text } from "./styles";

export const FourOFour = () => (
  <Container>
    <StyledIcon name="kic-icon" />
    <Text big>404</Text>
    <Text>{`It looks like you've ran down the wrong path!`}</Text>
    <Text>
      Click <span onClick={() => Router.back()}>here</span>, to return back with
      the group.
    </Text>
  </Container>
);
