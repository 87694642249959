import React from "react";
import Head from "next/head";
import { FourOFour } from "./FourOFour";

const FourOFourPage = () => (
  <>
    <Head>
      <title>Page Not Found — Kic</title>
    </Head>
    <FourOFour />
  </>
);

export default FourOFourPage;
